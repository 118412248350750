@import "react-toastify/dist/ReactToastify.min.css";
@import "./tostify.css";

@font-face {
  font-family: Neue Haas Grotesk Display Pro;
  font-weight: 400;
  src: url(https://cdn.talentgarden.com/fonts/neue/NHaasGroteskDSPro-55Rg.ttf);
}

@font-face {
  font-family: Neue Haas Grotesk Display Pro;
  font-weight: 600;
  src: url(https://cdn.talentgarden.com/fonts/neue/NHaasGroteskDSPro-65Md.ttf);
}

@font-face {
  font-family: Lubalin;
  src: url(https://cdn.talentgarden.com/fonts/lubalin/lubalingraphstd-demi-webfont.ttf);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: env(--font-family-default);
  white-space: break-spaces;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (min-width: env(--breakpoint-small)) {

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    white-space: pre-line;
  }
}

/* fix dropdown react components */
[data-radix-popper-content-wrapper] {
  z-index: 1 !important;
  height: 100%;
}
