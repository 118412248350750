/* .SelectedWeekExample {
    right: 0;
    position: absolute;
    z-index: 1000;
    border-radius: env(--radius-8);
    background-color: hsl(env(--color-support-white));
    box-shadow: 0px 4px 20px 0px #0F0F0F1F;
    top:env(--space-104);
} */


/* .DayPicker-NavButton{
    top: 50%!important;
}

.DayPicker-NavButton--prev{left: 0!important;}
.DayPicker-NavButton--next{right: 0!important;} */

.DayPicker-Caption{
    font-size: env(--font-size-14)!important;
}

.DayPicker{
    width: 100%;
}

 .DayPicker-Month {
    border-collapse: separate;
    font-weight: 400;
    color: hsl(env(--color-support-black))!important;
}
 .DayPicker-WeekNumber {
    outline: none;
}
.DayPicker-Weekday {
    font-weight: 700;
    color: hsl(env(--color-support-black))!important;
    font-size: env(--font-size-11)!important;
}
 .DayPicker-Day {
    outline: none;
    padding: env(--space-12)!important;
    /* border: 1px solid transparent; */
    font-size: env(--font-size-14)!important;

}
 .DayPicker-Day--hoverRange {
    background-color: hsl(env(--color-grey-5)) !important;
    border-radius: 0!important;
}

.DayPicker-Day--today {
    color: hsl(env(--color-support-black))!important;
}

 .DayPicker-Day--selectedRange, .DayPicker-Day--selected {
    /* border-top-color: none !important; */
    border-bottom-color: none;
    background-color: hsl(env(--color-grey-10))!important;
    /* border-color: hsl(env(--color-grey-10)) hsl(env(--color-grey-10)) none; */
    color: #EE7660!important;
    border-radius: 0;


}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(:first-child):not(:last-child), .DayPicker-Day--outside{
    border-radius: 0!important;
    cursor: pointer!important;
} 

 .DayPicker-Day--selectedRangeStart {
    background-color: hsl(env(--color-grey-10)) !important;
    border-left: none;
    border-radius: 0!important;
    border-top-left-radius: 50px!important;
    border-bottom-left-radius: 50px!important;
}

 .DayPicker-Day--selectedRangeEnd {
    background-color: hsl(env(--color-grey-10)) !important;
    border-right: none;
    border-radius: 0!important;
    border-top-right-radius: 50px!important;
    border-bottom-right-radius: 50px!important;
}

 .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside, .DayPicker-Day--selectedRangeStart, .DayPicker-Day--selectedRangeEnd ).DayPicker-Day--selected,
 .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside, .DayPicker-Day--selectedRangeStart, .DayPicker-Day--selectedRangeEnd).DayPicker-Day--selected {
    border-radius: 0 !important;
    color: black !important;
    color: hsl(env(--color-support-brand))!important;
}
 .DayPicker-Day--hoverRange:hover {
    border-radius: 0 !important;
}

 
